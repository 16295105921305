const Address = () => {
  return (
    <div className="lg:w-1/3 lg:pl-5">
      <h2 className="font-display mb-4 text-xl dark:text-white text-white">
        Información
      </h2>
      <p className="dark:text-jacarta-300 text-jacarta-300 mb-6 text-lg leading-normal">
        {
          "Síguenos en nuestras redes sociales."
        }
      </p>

      <div className="dark:bg-jacarta-1200 bg-jacarta-1200 dark:border-jacarta-200 border-jacarta-200 rounded-2.5xl border bg-white p-10">
        <div className="mb-6 flex items-center space-x-5">
          <span className="dark:bg-white bg-white bg-light-base flex h-11 w-11 shrink-0 items-center justify-center rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 28 28"
              width="20"
              height="20"
              className="fill-jacarta-1200"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M13.975 6.86c-3.966 0-7.165 3.199-7.165 7.165s3.199 7.165 7.165 7.165c3.966 0 7.165-3.199 7.165-7.165s-3.199-7.165-7.165-7.165zM13.975 18.683c-2.563 0-4.658-2.089-4.658-4.658s2.089-4.658 4.658-4.658c2.569 0 4.658 2.089 4.658 4.658s-2.095 4.658-4.658 4.658v0zM23.105 6.567c0 0.929-0.748 1.671-1.671 1.671-0.929 0-1.671-0.748-1.671-1.671s0.748-1.671 1.671-1.671c0.923 0 1.671 0.748 1.671 1.671zM27.85 8.263c-0.106-2.239-0.617-4.222-2.257-5.856-1.634-1.634-3.617-2.145-5.856-2.257-2.307-0.131-9.223-0.131-11.531 0-2.233 0.106-4.216 0.617-5.856 2.251s-2.145 3.617-2.257 5.856c-0.131 2.307-0.131 9.223 0 11.531 0.106 2.239 0.617 4.222 2.257 5.856s3.617 2.145 5.856 2.257c2.307 0.131 9.223 0.131 11.531 0 2.239-0.106 4.222-0.617 5.856-2.257 1.634-1.634 2.145-3.617 2.257-5.856 0.131-2.307 0.131-9.217 0-11.524v0zM24.869 22.263c-0.486 1.222-1.428 2.164-2.657 2.657-1.84 0.73-6.205 0.561-8.238 0.561s-6.404 0.162-8.238-0.561c-1.222-0.486-2.164-1.428-2.657-2.657-0.73-1.84-0.561-6.205-0.561-8.238s-0.162-6.404 0.561-8.238c0.486-1.222 1.428-2.164 2.657-2.657 1.84-0.73 6.205-0.561 8.238-0.561s6.404-0.162 8.238 0.561c1.222 0.486 2.164 1.428 2.657 2.657 0.73 1.84 0.561 6.205 0.561 8.238s0.168 6.404-0.561 8.238z"></path>
            </svg>
          </span>

          <div>
            
            <a
              href="https://www.instagram.com/irbin.pe/"
              target="_blank"
                rel="noopener noreferrer"
              className="dark:hover:text-accent hover:text-accent dark:text-jacarta-300 text-jacarta-300 text-sm ease-out duration-200"
            >
              @irbin.pe
            </a>
          </div>
        </div>

        <div className="mb-6 flex items-center space-x-5">
          <span className="dark:bg-white bg-white flex h-11 w-11 shrink-0 items-center justify-center rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="20"
              height="20"
              className="fill-jacarta-1200"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/>
            </svg>
          </span>

          <div>
            <a
              href="https://www.linkedin.com/company/cirsys/"
              target="_blank"
                rel="noopener noreferrer"
              className="dark:hover:text-accent hover:text-accent dark:text-jacarta-300 text-jacarta-300 text-sm ease-out duration-200"
            >
              linkedin.com/company/cirsys
            </a>
          </div>
        </div>

        <div className="flex items-center space-x-5">
          <span className="dark:bg-white bg-white bg-light-base flex h-11 w-11 shrink-0 items-center justify-center rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              className="fill-jacarta-1200"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M2.243 6.854L11.49 1.31a1 1 0 0 1 1.029 0l9.238 5.545a.5.5 0 0 1 .243.429V20a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.283a.5.5 0 0 1 .243-.429zM4 8.133V19h16V8.132l-7.996-4.8L4 8.132zm8.06 5.565l5.296-4.463 1.288 1.53-6.57 5.537-6.71-5.53 1.272-1.544 5.424 4.47z" />
            </svg>
          </span>

          <div>
            <a
              href="mailto:contacto@cirsystech.com"
              className="dark:hover:text-accent hover:text-accent dark:text-jacarta-300 text-jacarta-300 text-sm ease-out duration-200"
            >
              contacto@cirsystech.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
