import Link from "next/link";
import useForm from "./useForm";
import validade from "./validateForm";

const ContactForm = () => {

  const { isSubmitting, handleChange, values, handlePress, errors, modalSuccess, modalFail, setValues, isLoading } =
    useForm(validade);

  return (
    <form id="contact-form" method="post">
      <div className="flex space-x-7">
        <div className="mb-12 w-1/2 relative">
          {errors.message && <div className="absolute validate-message text-red"><p style={{ fontSize: "15px" }}>{errors.name}</p></div>}
          <label className="font-display mb-1 block text-sm dark:text-white text-white">
            Nombre *
          </label>
          <input
            name="name"
            className="contact-form-input dark:bg-jacarta-1200 bg-jacarta-1200 border-jacarta-200 dark:hover:ring-accent/10 hover:ring-accent/10 dark:focus:ring-accent focus:ring-accent dark:border-jacarta-200 border-jacarta-200 dark:placeholder:text-jacarta-300 placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white text-white"
            id="name"
            type="text"
            value={values.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-12 w-1/2 relative">
          {errors.email && <div className="absolute validate-message text-red"><p style={{ fontSize: "15px" }}>{errors.email}</p></div>}
          <label className="font-display mb-1 block text-sm dark:text-white text-white">
            Email *
          </label>
          <input
            name="email"
            className="contact-form-input dark:bg-jacarta-1200 bg-jacarta-1200 border-jacarta-200 dark:hover:ring-accent/10 hover:ring-accent/10 dark:focus:ring-accent focus:ring-accent dark:border-jacarta-200 border-jacarta-200 dark:placeholder:text-jacarta-300 placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white text-white"
            id="email"
            type="email"
            value={values.email}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <div className="mb-4 relative">
        {errors.message && <div className="absolute validate-message text-red"><p style={{ fontSize: "15px" }}>{errors.message}</p></div>}
        <label className="font-display mb-1 block text-sm dark:text-white text-white">
          Mensaje *
        </label>
        <textarea
          id="message"
          className="contact-form-input dark:bg-jacarta-1200 bg-jacarta-1200 border-jacarta-200 dark:hover:ring-accent/10 hover:ring-accent/10 dark:focus:ring-accent focus:ring-accent dark:border-jacarta-200 border-jacarta-200 dark:placeholder:text-jacarta-300 placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white text-white"
          name="message"
          rows="5"
          value={values.message}
          onChange={handleChange}
          required
        ></textarea>
      </div>

      <div className="mb-6 flex items-center space-x-2">
        {/* <input
          type="checkbox"
          id="contact-form-consent-input"
          name="agree-to-terms"
          className="checked:bg-accent dark:bg-jacarta-1200 text-accent border-jacarta-200 focus:ring-accent/20 dark:border-jacarta-200 h-5 w-5 self-start rounded focus:ring-offset-0 cursor-pointer"
        /> */}
        <label className="dark:text-jacarta-200 text-jacarta-200 text-sm">
          Al hacer click en enviar estás aceptando nuestra
          <Link href="/privacidad">
            <a className="text-accent dark:hover:text-accent-dark hover:text-accent-dark ease-out duration-200"> Política de Privacidad</a>
          </Link>
        </label>
      </div>

      <button
        disabled={isLoading}
        type="submit"
        className="bg-accent hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-black transition-all ease-out duration-200"
        id="contact-form-submit"
        onClick={handlePress}
      >
        <div
          style={{
            display: 'flex',
            justifyContent:'center',
            alignItems:'center',
            height:'25px'
          }}
        >
          {!isLoading && ("ENVIAR")}
          {isLoading && (<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>)}
        </div>

      </button>

      {modalSuccess && (
        <div
          id="contact-form-notice"
          className="text-accent dark:bg-jacarta-700 rounded-2lg dark:border-accent flex items-center justify-center border p-8 text-center group dark:hover:border-accent hover:dark:bg-jacarta-1100 ease-out duration-200 mt-5"
        >
          Su correo electrónico ha sido enviado existosamente!
        </div>
      )}
      {modalFail && (
        <div
          id="contact-form-notice"
          className="text-red dark:bg-jacarta-700 rounded-2lg dark:border-red flex items-center justify-center border p-8 text-center group dark:hover:border-accent hover:dark:bg-jacarta-1100 ease-out duration-200 mt-5"
        >
          Hubo un problema al enviar su correo electrónico
        </div>
      )}
    </form>
  );
};

export default ContactForm;
