export default function validadeInfo(values) {
    let errors = {};

    //Name
    if (!values.name.trim()) {
        errors.name = "* Completa tu nombre";
    }

    //Email
    if (!values.email) {
        errors.email = '* Complete su email';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = '* Email inválido';
    }

    //Message
    if (!values.message) {
        errors.message = '* Háblanos de tu proyecto';
    }

    return errors;
}

